import React from 'react'
import {
  Segment,
  Container,
  Divider,
  Item,
  Header,
  Image,
} from 'semantic-ui-react'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import { FormattedMessage } from 'gatsby-plugin-intl'
import './clients.css'

import engie_logo from '../images/Clients/engie-logo.png'
import om_logo from '../images/Clients/om-logo.png'
import electrolux_logo from '../images/Clients/electrolux-logo.png'
import bonduelle_logo from '../images/Clients/bonduelle-logo.png'
import agriconomie_logo from '../images/Clients/agriconomie-logo.png'
import banque_casino_logo from '../images/Clients/banque-casino.png'
import banque_postale_logo from '../images/Clients/banque-postale-logo.svg'
import caarea_logo from '../images/Clients/caarea-logo.svg'
import ikea_logo from '../images/Clients/ikea-logo.svg'
import msd_logo from '../images/Clients/msd-logo.svg'
import ponant_logo from '../images/Clients/ponant-logo.png'

const CaseStudies = () => (
  <Segment vertical>
    <Segment as="section" basic>
      <Container text>
        <Header as="h3">
          <FormattedMessage id="subtitle-clients-3" />
        </Header>
        <Item.Group>
          <Item>
            <Item.Image size="small" src={engie_logo} />
            <Item.Content>
              <Item.Header>ENGIE Digital</Item.Header>
              <Item.Meta>
                <FormattedMessage id="engie-case-study-meta" />
              </Item.Meta>
              <Item.Description>
                <a
                  href="https://aws.amazon.com/fr/solutions/case-studies/engie-digital-sagemaker/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="read-more" />{' '}
                  <span role="img" aria-label="French flag">
                    🇫🇷
                  </span>
                </a>
                <br />
                <a
                  href="https://aws.amazon.com/solutions/case-studies/engie-digital-sagemaker/?nc1=h_ls"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  (
                  <span role="img" aria-label="USA flag">
                    🇺🇸
                  </span>{' '}
                  version)
                </a>
              </Item.Description>
            </Item.Content>
          </Item>
          <Container text>
            <Divider section />
          </Container>
          <Item>
            <Item.Image size="small" src={ponant_logo} />
            <Item.Content>
              <Item.Header>PONANT</Item.Header>
              <Item.Meta>
                <FormattedMessage id="ponant-case-study-meta" />
              </Item.Meta>
              <Item.Description>
                <a
                  href="https://www.linkedin.com/pulse/une-intelligence-artificielle-analyse-la-voix-pour-am%C3%A9liorer-"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="read-more" />{' '}
                  <span role="img" aria-label="French flag">
                    🇫🇷
                  </span>
                </a>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Container>
    </Segment>
  </Segment>
)

const ClientsPrimary = () => (
  <Segment vertical>
    <Segment as="section" basic>
      <Container text>
        <Header as="h3">
          <FormattedMessage id="subtitle-clients-2" />
        </Header>
      </Container>
      <div className="logo client-cards">
        <Image.Group className="logo-group" size="small">
          <Image spaced src={caarea_logo} />
          <Image spaced src={msd_logo} />
          <Image spaced src={agriconomie_logo} />
          <Image spaced src={engie_logo} />
          <Image spaced src={bonduelle_logo} />
          <Image spaced src={banque_casino_logo} />
          <Image spaced src={om_logo} />
          <Image spaced src={electrolux_logo} />
          <Image spaced src={ikea_logo} />
          <Image spaced src={banque_postale_logo} />
        </Image.Group>
      </div>
    </Segment>
  </Segment>
)

const TestimonialsPrimary = () => (
  <Segment vertical>
    <Segment as="section" basic>
      <Container text>
        <Header as="h3">
          <FormattedMessage id="subtitle-clients-1" />
        </Header>
        <Container text textAlign="justified" className="client-cards">
          <Item.Group>
            <Item>
              <Item.Image size="small" src={om_logo} className="OM-logo" />
              <Item.Content>
                <Item.Header>Samir Dilmi</Item.Header>
                <Item.Meta>
                  <span className="price">
                    Head of Data Marketing, Olympique de Marseille
                  </span>
                </Item.Meta>
                <Item.Description>
                  <p>
                    <FormattedMessage id="om-testimonial" />
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>
            <Container text>
              <Divider section />
            </Container>
            <Item>
              <Item.Image size="small" src={engie_logo} />
              <Item.Content>
                <Item.Header>Lounès Achab</Item.Header>
                <Item.Meta>
                  <span className="price">
                    Lead Data Engineering, Engie Digital
                  </span>
                </Item.Meta>
                <Item.Description>
                  <p>
                    <FormattedMessage id="engie-testimonial" />
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>
            <Container text>
              <Divider section />
            </Container>
            <Item>
              <Item.Image size="small" src={agriconomie_logo} />
              <Item.Content>
                <Item.Header>Paolin Pascot</Item.Header>
                <Item.Meta>
                  <span className="price">Co-founder & CEO, Agriconomie</span>
                </Item.Meta>
                <Item.Description>
                  <p>
                    <FormattedMessage id="agriconomie-testimonial" />
                  </p>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Container>
      </Container>
    </Segment>
  </Segment>
)

const Clients = () => (
  <Layout>
    <div className="ClientsPage">
      <PageHeader />
      <CaseStudies />
      <TestimonialsPrimary />
      <ClientsPrimary />
    </div>
  </Layout>
)

export default Clients
